<template>
  <phi-page color="#f2f2f2">
    <div slot="toolbar">
      <mu-icon-button
        @click="$router.go(-1)"
        icon="arrow_back"
      />
      <h1
        v-if="debit.period"
      >{{ debit.period.name }} {{ debit.sequence ? '#' + debit.sequence : null }}</h1>
    </div>

    <div
      class="tucompraBack"
      v-if="showMessage"
    >{{ $t('notice.tuCompra') }}</div>

    <phi-debit
      v-if="debit.id"
      :debit="debit"
    ></phi-debit>

    <div v-if="paymentIsAllowed && isPaymentSuccess == null">

      <phi-mercadopago
        v-if="mercadoPago.isEnabled && mercadoPago.publicKey && debit.id"
        :public-key="mercadoPago.publicKey"
        :reference-id="'1' + debit.id"
        :ammount="debit.interests ? parseFloat(debit.balance) + parseFloat(debit.interests.value) : parseFloat(debit.balance)"

        @success="isPaymentSuccess = true"
        @error="isPaymentSuccess = false"
      ></phi-mercadopago>

      <phi-tucompra
        v-else-if="showTucompraButton"
        :url="tuCompra.url"
        :usuario="tuCompra.usuario"
        :factura="'1'+debit.id"
        :valor="debit.interests ? parseFloat(debit.balance) + parseFloat(debit.interests.value) : parseFloat(debit.balance)"
        :descripcionFactura="debit.period.name + ' #1' + debit.id"
        :nombreComprador="user.firstName"
        :apellidoComprador="user.lastName"
        tipoDocumentoComprador
        :documentoComprador="user.document"
        :correoComprador="user.email"
        :telefonoComprador="debit.responsible.phone"
        :paisComprador="debit.responsible.address_location_text.split(',')[2] ? debit.responsible.address_location_text.split(',')[2].trim() : ''"
        :ciudadComprador="debit.responsible.address_location_text.split(',')[0] ? debit.responsible.address_location_text.split(',')[0].trim() : ''"
        :direccionComprador="debit.responsible.address"
        :celularComprador="debit.responsible.mobile"
        @success="isPaymentSuccess = true"
        @error="isPaymentSuccess = false"
      ></phi-tucompra>
    </div>

    <!--<div v-if="isPaymentSuccess === true">
			Pago recibido!
		</div>
		<div v-if="isPaymentSuccess === false">
			Error recibiendo pago <br>
			<button @click="isPaymentSuccess = null">Reintentar</button>
    </div>-->

    <div
      v-if="isPaymentSuccess === true"
      class="alert"
    >
      <i class="material-icons">check_circle_outline</i>
      Pago recibido!
    </div>
    <div
      v-if="isPaymentSuccess === false"
      class="alert alert-error"
    >
      <i
        class="material-icons"
        @click="isPaymentSuccess = null"
      >autorenew</i>
      Error recibiendo pago
    </div>

    <!--<phi-tucompra
			v-if="debit.id && app.data.tucompraURL"
			:url="app.data.tucompraURL"
			:usuario="app.data.tucompraID"
			:factura="'1'+debit.id"
			:valor="debit.interests ? parseFloat(debit.balance) + parseFloat(debit.interests.value) : parseFloat(debit.balance)"
			:descripcionFactura="debit.period.name + ' #1' + debit.id"
			:nombreComprador="user.firstName"
			:apellidoComprador="user.lastName"
			tipoDocumentoComprador=""
			:documentoComprador="user.document"
			:correoComprador="user.email"
			:telefonoComprador="debit.responsible.phone"
			:paisComprador="debit.responsible.address_location_text.split(',')[2] ? debit.responsible.address_location_text.split(',')[2].trim() : ''"
			:ciudadComprador="debit.responsible.address_location_text.split(',')[0] ? debit.responsible.address_location_text.split(',')[0].trim() : ''"
			:direccionComprador="debit.responsible.address"
			:celularComprador="debit.responsible.mobile"
			@complete="showMessage = true"

		>
			<div class="gopay phi-media phi-card" v-if="debit.balance > 0 && !showMessage">
				<mu-icon value="credit_card" class="phi-media-figure"></mu-icon>
				<div class="phi-media-body">{{ (debit.interests ? parseFloat(debit.balance) + parseFloat(debit.interests.value) : parseFloat(debit.balance)) | currency }}</div>
				<mu-icon value="arrow_forward" class="phi-media-right"></mu-icon>
			</div>
    </phi-tucompra>-->
  </phi-page>
</template>

<script>
import PhiDebit from "@/components/Phi/Debit.vue";
import PhiTucompra from "@/components/Phi/Tucompra.vue";
import PhiMercadopago from "@/components/Phi/Mercadopago.vue";

import store from "@/store/index.js";

export default {
  components: { PhiDebit, PhiTucompra, PhiMercadopago },
  data() {
    return {
      tuCompra: {
        url: this.$store.state.settings.tuCompra.url,
        usuario: this.$store.state.settings.tuCompra.id
        //url: 'https://demover3-1.tucompra.net/tc/app/inputs/compra.jsp',
        //usuario: 'h7h5vnc4ina8dd19'
      },

      // mercadoPago: {
      //   isEnabled: true,
      //   publicKey: "TEST-59cb6b6c-03db-4b0f-b82e-82a4a6119fdd"
      // },

      mercadoPago: {
        isEnabled:
          typeof this.$store.state.settings != "undefined" &&
          typeof this.$store.state.settings.mercadoPago != "undefined"
            ? this.$store.state.settings.mercadoPago.isEnabled
            : false,

        publicKey:
          typeof this.$store.state.settings != "undefined" &&
          typeof this.$store.state.settings.mercadoPago != "undefined"
            ? this.$store.state.settings.mercadoPago.publicKey
            : null
      },

      debit: {},
      showMessage: false,
      currentDebit: null,
      isPaymentSuccess: null,
      paymentIsAllowed: false
    };
  },

  computed: {
    api() {
      return this.$store.state.api;
    },

    user() {
      return this.$store.state.user;
    },

    showTucompraButton() {
      return (
        this.debit.id &&
        this.$store.state.settings.tuCompra
      );
    }
  },

  created() {
    /*
    Establecer el permiso via API:

    POST https://INSTANCIA.api.phidias.co/authorization/rules
    {
      "url": "/people/{personId}/billing/pay/{debitId}",
      "audience": null,
      "rule": true
    }
    */

    let urlAllowedDebit = '/people/' + this.$store.state.user.id + '/billing/pay/' + this.$route.params.debitId;
    // let urlAllowedDebit = `/people/${this.$store.state.user.id}/billing/pay/${this.$route.params.debitId}`;  // THIS FUCKS UP VUE "watch" internally (!)

    this.api
      .allowed(urlAllowedDebit)
      .then(boolResponse => (this.paymentIsAllowed = boolResponse));
  },

  beforeRouteEnter(to, from, next) {
    store.state.api.get(`v3/debits/${to.params.debitId}`).then(debit =>
      next(vm => {
        vm.debit = debit;
        vm.debit.responsible =
          typeof vm.debit.responsible == "object"
            ? debit.responsible
            : { id: debit.responsible, address_location_text: "" };
      })
    );
  }
};
</script>

<style lang="scss" scoped>
.gopay {
  cursor: pointer;
  margin-top: 24px;
  background-color: rgb(28, 137, 184);
  color: #fff;
  align-items: center;

  &:hover {
    background-color: rgb(36, 180, 242);
  }

  .phi-media-body {
    font-size: 1.2em;
  }
}

.alert {
  width: 100%;
  color: #fff;
  padding: 15px;
  margin: 10px 0;
  background: #009688;
  border-radius: 5px;
  border: 1px solid #00796b;

  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    margin-right: 20px;
  }
}

.alert-error {
  background: #ef5350;
  border: 1px solid #d32f2f;
}

.tucompraBack {
  background-color: rgb(251, 244, 244);
  padding: 20px;
  margin-top: 15px;
  text-align: center;
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      box-shadow: 0 1px 0px 0 rgb(246, 167, 167);
    }
    50% {
      box-shadow: 0 1px 3px 0 rgb(246, 7, 7);
    }
    100% {
      box-shadow: 0 1px 0px 0 rgb(246, 167, 167);
    }
  }
}
</style>
