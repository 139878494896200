<template>
	<div class="phi-debit">

		<div class="card phi-card">
			<div class="card-content">
				<div class="name">{{ debit.person.firstname }} {{ debit.person.lastname }}</div>

				<div class="date" v-if="debit.issue_date">
					<i class="material-icons">today</i> Expedido: <small><i>{{ $date(debit.issue_date) }}</i></small>
				</div>

				<div :class="['date', debit.expiration_date < dateNow ? 'expired' : '']" v-if="debit.expiration_date">
					<i class="material-icons">event_busy</i> Vence: <small><i>{{ $date(debit.expiration_date) }}</i></small>
				</div>

				<!-- concepts -->
				<div class="concepts">
					<div class="row" v-for="price in debit.prices" :key="price.id">
						<div class="details">
							{{ price.concept != price.name ? price.concept : '' }} {{ price.name }}
							<div class="observations" v-if="price.observations">{{ price.observations }}</div>
						</div>
						<div class="price">{{ price.balance | currency }}</div>
					</div>

					<!-- intereses -->
					<div class="row" v-if="debit.interests">
						<div class="details">
							{{ $t('component.phi.debit.interests') }}
						</div>
						<div class="price" style="color: #EF5350">{{ debit.interests.value | currency }}</div>
					</div>

					<div class="observations" v-if="debit.interests.observation">{{ debit.interests.observation }}</div>
				</div>
			</div>

			<!-- total -->
			<div class="total">
				TOTAL
				<span v-if="debit.interests">
					{{ (parseFloat(debit.interests.value) + parseFloat(debit.balance)) | currency }}
				</span>
				<span v-else>{{ parseFloat(debit.balance) | currency }}</span>
			</div>
		</div>

	</div>
</template>

<script>
export default{
	props: ['debit'],

	data() {
		return {
			dateNow: Math.floor(Date.now() / 1000)
		}
	}
}
</script>

<style lang="scss" scoped>
$color: #1565C0;

.card {
	border-radius: 10px;
	border-top: 4px solid $color;

	.card-content {
		padding: 25px;

		.name {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 20px;
		}

		.date {
			opacity: .9;
			display: flex;
			font-size: 13px;
			margin-bottom: 5px;
			align-items: center;

			.material-icons {
				opacity: .6;
				font-size: 19px;
				margin-right: 10px;
			}
		}

		.expired {
			color: #EF5350;
		}

		.concepts {
			margin-top: 40px;

			.row {
				display: flex;
				flex-wrap: wrap;
				padding: 10px 0;

				.details {
					width: 100px;
					flex-grow: 1;
					font-weight: bold;

				}

				.price {
					width: 100px;
					font-size: 13px;
					font-weight: bold;
					text-align: right;
					align-self: flex-star;
				}
			}

			.observations {
				opacity: .7;
				font-size: 14px;
				margin-top: 7px;
				text-align: justify;
				font-weight: normal;
			}
		}
	}


	.total {
		color: #fff;
		display: flex;
		padding: 20px;
		background: $color;
		align-items: center;
		border-radius: 0 0 10px 10px;
		justify-content: space-between;
	}
}
</style>